import type { WorkerMessageFunction, WorkerFunctionBroadcastMessage } from "./types.ts";

/**
 * Message channel on the worker thread
 */
export class WorkerMessageChannel {
    private _port: MessagePort;
    /** Executable functions by the broadcast channel */
    private _functions: Record<string, WorkerMessageFunction> = {};
    /** Callbacks store */
    private _callbacks: Record<string, Function> = {};

    id: string;

    get initialized() {
        return this._port != null;
    }

    constructor(pOptions: {
        id: string,
        functions?: Record<string, WorkerMessageFunction>
        port: MessagePort
    }) {
        this.id = pOptions.id;
        this._port = pOptions.port;
        if (pOptions.functions) {
            this._functions = pOptions.functions;
        }

        this._port.onmessage = this._onMessage.bind(this);
        this._port.postMessage(JSON.stringify({
            operation: 'connected'
        }));
    }

    /** 
     * Adds function as an executable for the broadcast channel in the current context
     * @param {string} pName Name of the function
     * @param {Function} pFn Function that will be executed 
     */
    registerFunction(pName: string, pFn: WorkerMessageFunction) {
        this._functions[pName] = pFn;
    }

    /**
     * Will broadcast function execution on the channel
     * @param {string} pName Name of the function to execute on the receiver
     * @param {string} pArgument Optional argument that will be passed to the executed function, must be string type
     * @param {number} pTimeout The timeout after which the promise will reject if no response is received
     */
    async execute(pName: string, pArgument?: string, pTimeout: number = 10_000) {
        if (this._port != null) {
            const uid = crypto.randomUUID();
            const messageObject: WorkerFunctionBroadcastMessage = {
                operation: 'execute',
                name: pName,
                payload: pArgument,
                meta: {
                    uid: uid,
                    broadcaster: window.location.href
                }
            };
            let promiseRes: Function;
            let promiseRej: Function;
            const promise = new Promise<any>((res, rej) => {
                promiseRes = res;
                promiseRej = rej;
            });
            const timeoutDebounce = window.setTimeout(() => {
                delete this._callbacks[uid];
                promiseRej(new Error('Broadcast Channel timeout'));
            }, pTimeout);
            this._callbacks[uid] = (success?: boolean, result?: string) => {
                window.clearTimeout(timeoutDebounce);
                delete this._callbacks[uid];
                if (!success) {
                    promiseRej(new Error(result ?? 'Recieved failed status'));
                } else {
                    promiseRes(result);
                }
            };
            this._port.postMessage(JSON.stringify(messageObject));
            return promise;
        }
    }


    private async _onMessage(e: MessageEvent) {
        const message = e.data;
        if (!message) {
            console.warn('Received empty broadcast message', message);
            return;
        }
        const messageObject = JSON.parse(message) as WorkerFunctionBroadcastMessage;
        switch (messageObject.operation) {
            case 'execute':
                let responseObject = {
                    operation: 'callback',
                    meta: {
                        uid: messageObject.meta.uid,
                        broadcaster: window.location.href
                    }
                } as WorkerFunctionBroadcastMessage;
                if (messageObject.name && typeof this._functions[messageObject.name] === 'function') {
                    let result: string | undefined;
                    let success: boolean;
                    try {
                        result = await this._functions[messageObject.name](messageObject.payload, new URL(messageObject.meta.broadcaster));
                        success = true;
                    } catch (ex: any) {
                        result = ex?.message ?? ex;
                        success = false;
                    }
                    responseObject.payload = result;
                    responseObject.success = success;
                    this._port?.postMessage(JSON.stringify(responseObject));
                } else {
                    responseObject.payload = `Could not execute function with name: ${messageObject.name}`;
                    responseObject.success = false;
                    this._port?.postMessage(JSON.stringify(responseObject));
                }
                break;
            case 'callback':
                const uid = messageObject.meta.uid;
                const callback = this._callbacks[uid];
                if (typeof callback === 'function') {
                    callback(messageObject.success, messageObject.payload);
                }
                break;
        }
    }

}
